import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import classnames from 'classnames';
import i18n from 'i18next';

import Logo from '../../../core/components/Logo';
import Card from '../../../core/components/Card';
import Input from '../../../core/components/Input';
import Alert, { AlertTitle } from '../../../core/components/Alert';
import ProgressButton from '../../../core/containers/ProgressButton';
import LoginWrapper from '../../components/LoginWrapper';
import Form from '../../../core/components/Form';

import { CREATE_USER } from '../../queries';
import { LOGIN_PATH } from '../../constants';

const SignUpFormCard = (props) => {
    const { onSubmit, error } = props;
    const { t } = useTranslation('user');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [customerName, setCustomerName] = useState('');

    const formComplete = firstName && lastName && email && customerName;

    const handleSubmit = () => onSubmit({ firstName, lastName, email, customerName });

    return (
        <Card level={2} className={classnames('login__card', 'login__card--wide')}>
            <Logo className="login__logo" />

            <h1 className="login__title">{t('signUp.title')}</h1>
            <Form>
                {error && (
                    <Alert className="alert--no-margin" severity="error">
                        <AlertTitle>{error?.message}</AlertTitle>
                    </Alert>
                )}

                <div className="form__group">
                    <label className="form__label">{t('signUp.email')}</label>
                    <Input
                        className="form__input"
                        type="email"
                        placeholder={t('signUp.emailPlaceholder')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className="form__field-group form__field-group--horizontal">
                    <div className="form__group">
                        <label className="form__label">{t('signUp.firstName')}</label>
                        <Input
                            className="form__input"
                            placeholder={t('signUp.firstNamePlaceholder')}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>

                    <div className="form__group">
                        <label className="form__label">{t('signUp.lastName')}</label>
                        <Input
                            className="form__input"
                            placeholder={t('signUp.lastNamePlaceholder')}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                </div>

                <div className="form__group">
                    <label className="form__label">{t('signUp.customerName')}</label>
                    <Input
                        className="form__input"
                        placeholder={t('signUp.customerNamePlaceholder')}
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                    />
                </div>

                <ProgressButton label={t('signUp.submit')} disabled={!formComplete} onClick={handleSubmit} />
            </Form>

            <div className="login__below-card">
                <Link to={LOGIN_PATH}>{t('signUp.back')}</Link>
            </div>
        </Card>
    );
};

const SignUpCompleteCard = (props) => {
    const { t } = useTranslation('user');

    return (
        <Card level={2} className={classnames('login__card', 'login__card--wide')}>
            <Logo className="login__logo" />

            <h1 className="login__title">{t('signUp.completeTitle')}</h1>

            <div className="login__description">{t('signUp.completeDescription')}</div>

            <div className="login__below-card">
                <Link to={LOGIN_PATH}>{t('signUp.back')}</Link>
            </div>
        </Card>
    );
};

const SignUp = (props) => {
    const [submitted, setSubmitted] = useState(false);
    const [createUser, { loading, error }] = useMutation(CREATE_USER);

    const handleSubmit = (formData) => {
        return createUser({
            variables: {
                input: {
                    ...formData,
                    language: i18n.language,
                },
            },
        })
            .then((mutationResult: any) => {
                setSubmitted(true);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <LoginWrapper>
            {!submitted ? <SignUpFormCard onSubmit={handleSubmit} error={error} /> : <SignUpCompleteCard />}
        </LoginWrapper>
    );
};

export default SignUp;
