import { useTranslation } from 'react-i18next';
import moment from 'moment';

import './style.scss';

export const EXTERNAL_STATUS_FILTER_CHOICES = {
    SUCCESSFULLY_ENTERED_API_STATUS: 'Erfolgreich_erfasst',
    ENTER_MANUALLY_API_STATUS: 'Manuell_erfassen',
    ENTERED_MANUALLY_API_STATUS: 'Manuell_erfasst',
    INCOMPLETE_API_STATUS: 'Unvollständig',
    EMPTY_API_STATUS: '__empty__',
};

export const formatSender = (record) => {
    const { t } = useTranslation('assistance');

    const senderEmail = record?.sourceFile.sender?.email;
    const senderName = record?.sourceFile.sender?.name;

    if (!senderEmail) {
        return [t('overview.columns.notFound')];
    }

    if (senderName) {
        return [senderName, senderEmail];
    } else {
        return [senderEmail];
    }
};

export const formatCustomer = (record) => {
    const { t } = useTranslation('assistance');
    const senderCompany = record.sender;

    if (!senderCompany) {
        return null;
    }
    if (senderCompany?.includes('(')) {
        //record.sender comes like 'CompanyName (CompanyNumber)';
        const parts = senderCompany.trim().split('(');
        if (parts?.length > 1) {
            parts[1] = parts[1].replace(/\)\s*$/, '');
        }
        return parts.filter((part) => part.length > 0);
    }
    return [senderCompany];
};

export const formatSubject = (record) => {
    return record?.subject || record?.sourceFile?.name || null;
};

export const formatTime = (time) => {
    const parsed = moment(time);
    const isToday = parsed.isSame(new Date(), 'day');

    if (isToday) {
        return parsed.format('LT');
    } else {
        return parsed.format('lll');
    }
};
