import { gql } from '@apollo/client';

export const GET_VIEWER = gql`
    query {
        viewer {
            id
            firstName
            lastName
            email
            lastLogin
            language
            showExplanationsByDefault
            sendEmailNotification
            isStaff
            canSwitchSupportUser
            passwordEnabled
            dateJoined
            customer {
                id
                name
                shortName
                isAutomationDashboardEnabled
                isPropertyBillsEnabled
                isOrdersEnabled
                isOrderConfirmationsEnabled
                isRfqsEnabled
                isInvoicesEnabled
                isDeliveryNotesEnabled
                isListOfServicesEnabled
                isUniversalEnabled
                isActive
                customizationKey
                createdAt
            }
            groups {
                id
                name
                memberCount
            }
        }
    }
`;
