import { gql } from '@apollo/client';
import { FULL_CHANNEL } from './pages/Configuration/queries';
import { BASE_DOCUMENT, BASE_FIELD, PRIMITIVE_FIELD } from '../assistance/queries';

const FULL_UNIVERSAL = gql`
    fragment FullUniversal on UniversalDocument {
        ${BASE_DOCUMENT}
        customer {
            name
            customerNumber
            customerType
            misc
            contactPerson
            ${BASE_FIELD}
            address {
                addressId
                name
                postcode
                city
                country
                phone
                email
                companyName
                streetAndNr
                misc
                bbox
                pageIndex
                predictionConfidence
            }
            internalNote
        }
        
        lineItems {
            dynamicSchemaSerialized
        }
        
        dynamicSchemaSerialized
    }
`;

const FULL_RECORD = gql`
    ${FULL_UNIVERSAL}
    ${FULL_CHANNEL}
    fragment FullRecord on UniversalProcessingRecord {
        id
        createdAt
        deletedAt
        deletedBy {
            email
            firstName
            lastName
        }

        sender
        subject

        status

        canFinishAssistance
        canFinishHeaderAssistance
        canFinishLineItemsAssistance

        isHeaderCorrect: canFinishHeaderAssistance(ignoreThreshold: false)
        isLineItemsCorrect: canFinishLineItemsAssistance(ignoreThreshold: false)

        lockedBy {
            email
        }

        putOnHoldReason
        putOnHoldBy {
            email
            firstName
            lastName
        }

        assistedBy {
            email
            firstName
            lastName
        }

        assistedAt

        stepRun {
            id
            stepName
            manualOnly
            executionStatus
            error
            context
        }

        universalprocessingsteprunSet {
            id
            createdAt
            updatedAt
            stepName
            manualOnly
            executionStatus
            context
            error
        }

        channel {
            ...FullChannel
        }

        isDiscarded
        isTestDocument

        discardRecord {
            reason
            user {
                id
                firstName
                lastName
            }
            createdAt
            updatedAt
        }

        sourceFile {
            ... on Email {
                sender {
                    email
                    name
                }
                receivers {
                    email
                    name
                }
                subject
                messageId
                language
                receivedAt

                text
                html
                attachments {
                    cid
                    contentDisposition
                    name
                    type
                    url
                }

                senderClientId
                prediction
                predictionConfidence
                sourceUrl

                predictionConfidences
            }

            ... on File {
                name
                type
                url
            }
        }

        outputFile {
            ... on Export {
                files {
                    name
                    url
                }
            }
        }

        universalUnsaved {
            ...FullUniversal
        }

        externalReference
        deliveryRecord {
            externalReference
            externalMessage
            externalStatus
        }
        labelingStatus

        masterdataVersionsExtracted
    }
`;

export const GET_OVERVIEW_DATA = gql`
    query {
        universalProcessingChannels {
            id
            name
        }
    }
`;

export const GET_CHANNELS_OVERVIEW_DATA = gql`
    query {
        universalProcessingChannels {
            id
            name
            emailAddress
            team {
                id
                users {
                    email
                    firstName
                    lastName
                }
            }
        }
    }
`;

export const GET_TABLE_OVERVIEW_DATA = gql`
    query ($filters: [TableFilter], $orderBy: String, $offset: Int, $first: Int) {
        filteredUniversalProcessingRecordsList(filters: $filters, orderBy: $orderBy, offset: $offset, limit: $first) {
            totalCount
            records {
                id
                sender
                subject
                status
                createdAt
                finishedAt
                assistedAt
                assistedBy {
                    id
                    firstName
                    lastName
                    email
                }
                createdBy {
                    email
                    firstName
                    lastName
                }
                deletedAt
                deletedBy {
                    id
                    firstName
                    lastName
                    email
                }
                lockedBy {
                    email
                }
                stepRun {
                    id
                    stepName
                    manualOnly
                    executionStatus
                    error
                    context
                }
                channel {
                    id
                    name
                }
                isDiscarded
                deliveryRecord {
                    externalReference
                    externalMessage
                    externalStatus
                }
                discardRecord {
                    reason
                    user {
                        id
                        firstName
                        lastName
                        email
                    }
                    createdAt
                    updatedAt
                }
                isTestDocument
                canProcessAutomatically
                sourceFile {
                    ... on File {
                        name
                    }
                    ... on Email {
                        sender {
                            name
                            email
                        }
                    }
                }
            }
        }
    }
`;

export const GET_ASSISTANCE_OVERVIEW_DATA = gql`
    query ($recordId: UUID!, $filters: [TableFilter]) {
        universalProcessingRecordAssistancePagination(recordId: $recordId, filters: $filters) {
            filteredCount
            position
            previousRecordId
            nextRecordId
        }
    }
`;

export const GET_ASSISTANCE_DATA = gql`
    ${FULL_RECORD}
    ${FULL_UNIVERSAL}
    query ($id: UUID!) {
        universalProcessingRecord(id: $id) {
            ...FullRecord

            universalExtracted {
                ...FullUniversal
            }

            ocr {
                pagesCount
                processedFile {
                    url
                }
            }
        }
    }
`;

export const UPDATE_PROCESSING_RECORD = gql`
    mutation (
        $recordId: UUID!
        $fieldName: String
        $itemIndex: Int
        $action: String!
        $payload: GenericScalar
        $itemType: String
    ) {
        universalUpdate(
            recordId: $recordId
            fieldName: $fieldName
            itemIndex: $itemIndex
            action: $action
            payload: $payload
            itemType: $itemType
        ) {
            record {
                canFinishAssistance
                canFinishHeaderAssistance
                canFinishLineItemsAssistance

                isHeaderCorrect: canFinishHeaderAssistance(ignoreThreshold: false)
                isLineItemsCorrect: canFinishLineItemsAssistance(ignoreThreshold: false)
            }

            patch {
                op
                path
                from
                value
            }
        }
    }
`;

export const RESELECT_PROCESSING_RECORD = gql`
    mutation (
        $recordId: UUID!
        $fieldName: String
        $bbox: [[Float]]!
        $pageIndex: Int!
        $itemIndex: Int
        $itemType: String
    ) {
        universalReselect(
            recordId: $recordId
            fieldName: $fieldName
            bbox: $bbox
            pageIndex: $pageIndex
            itemIndex: $itemIndex
            itemType: $itemType
        ) {
            record {
                canFinishAssistance
                canFinishHeaderAssistance
                canFinishLineItemsAssistance

                isHeaderCorrect: canFinishHeaderAssistance(ignoreThreshold: false)
                isLineItemsCorrect: canFinishLineItemsAssistance(ignoreThreshold: false)
            }

            patch {
                op
                path
                from
                value
            }
        }
    }
`;

export const UNIVERSAL_EXTRACT_TEXT = gql`
    mutation ($recordId: UUID!, $pageIndex: Int!, $bbox: [[Float]]!) {
        universalExtractText(recordId: $recordId, pageIndex: $pageIndex, bbox: $bbox) {
            bbox
            text
        }
    }
`;

export const DELETE_UNIVERSAL = gql`
    mutation ($recordId: UUID!) {
        universalDelete(recordId: $recordId) {
            success
        }
    }
`;

export const RETRY_STEP = gql`
    mutation ($recordId: UUID!, $stepRunId: UUID!) {
        universalRetryStep(recordId: $recordId, stepRunId: $stepRunId) {
            success
        }
    }
`;

export const DISCARD_UNIVERSAL = gql`
    mutation ($recordId: UUID!, $reason: String) {
        universalDiscard(recordId: $recordId, reason: $reason) {
            success
        }
    }
`;

export const REOPEN_FOR_ASSISTANCE = gql`
    mutation ($recordId: UUID!, $reason: String) {
        universalReopenForAssistance(recordId: $recordId, reason: $reason) {
            success
        }
    }
`;

export const UPLOAD_FILE = gql`
    mutation ($channelId: UUID!, $files: [Upload]!, $batchId: String, $isTestingDocument: Boolean!) {
        universalUploadFile(
            input: { channelId: $channelId, files: $files, batchId: $batchId, isTestingDocument: $isTestingDocument }
        ) {
            records {
                id
            }
            success
        }
    }
`;

export const RE_UPLOAD_FILE = gql`
    mutation ($channelId: UUID!, $files: [Upload]!, $recordId: String!, $isTestingDocument: Boolean!) {
        universalUploadFile(
            input: { channelId: $channelId, files: $files, recordId: $recordId, isTestingDocument: $isTestingDocument }
        ) {
            records {
                id
            }
            success
        }
    }
`;

export const GET_NEXT_ASSISTANCE_RECORD = gql`
    query ($filters: [TableFilter], $channelId: UUID) {
        nextUniversalProcessingRecord(filters: $filters, channelId: $channelId) {
            id
        }
    }
`;

export const CREATE_CHANNEL = gql`
    mutation ($name: String!, $fromExistingChannelId: UUID) {
        createUniversalProcessingChannel(name: $name, fromExistingChannelId: $fromExistingChannelId) {
            channel {
                id
            }
            success
        }
    }
`;

export const GET_CUSTOMER_AUTOMATION_RATIO = gql`
    query ($startDate: Date!, $endDate: Date!) {
        customerUniversalAutomationRatio(startDate: $startDate, endDate: $endDate) {
            automationDays {
                date
                assistedCount
                automaticCount
                totalCount
                automationRatio
            }
            last5DaysAutomationRatio
        }
    }
`;

export const GET_CHANNEL_AUTOMATION_RATIO = gql`
    query ($channelId: UUID!, $startDate: Date!, $endDate: Date!) {
        channelUniversalAutomationRatio(channelId: $channelId, startDate: $startDate, endDate: $endDate) {
            automationDays {
                date
                assistedCount
                automaticCount
                totalCount
                automationRatio
            }
            last5DaysAutomationRatio
        }
    }
`;

export const GET_ALL_CHANNELS = gql`
    ${FULL_CHANNEL}
    query {
        universalProcessingChannels {
            ...FullChannel
        }
    }
`;

export const SEND_TO_LABELING = gql`
    mutation ($recordId: UUID!) {
        universalSendToLabeling(recordId: $recordId) {
            success
        }
    }
`;
