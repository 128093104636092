import DocumentConfiguration, { CONFIG_OPTIONS, DEFAULT_CONFIGS } from '../../../generic_document/pages/Configuration';
import * as DocumentQueries from './queries';
import { GET_OVERVIEW_DATA } from '../../queries';
import { CHANNEL_PATH, OVERVIEW_PATH } from '../../constants';
import React from 'react';
import { DocumentType } from '../../../generic_document/constants';
import { UPDATE_FIELDS_CONFIG } from './queries';
import ListOfServicesXMLSerializerConfiguration from '../Configuration/containers/ListOfServicesXMLSerializerConfiguration';

const Configuration = (props) => {
    const configOptions = {
        [CONFIG_OPTIONS.Document]: DEFAULT_CONFIGS[CONFIG_OPTIONS.Document],
        [CONFIG_OPTIONS.XmlSerializer]: ({ channel, documentConfiguration, handlers }) => {
            return (
                <ListOfServicesXMLSerializerConfiguration
                    config={channel?.xmlSerializerConfig}
                    onActivate={() => handlers.onCreate('xml_serializer_config')}
                    onDeactivate={() => handlers.onDelete('xml_serializer_config')}
                    onSubmit={(formData) => handlers.onUpdate({ xmlSerializerConfig: formData })}
                    documentType={documentConfiguration.documentTypeName + 'XmlSerializerConfig'}
                />
            );
        },
        [CONFIG_OPTIONS.MasterData]: DEFAULT_CONFIGS[CONFIG_OPTIONS.MasterData],
        [CONFIG_OPTIONS.SFTP]: DEFAULT_CONFIGS[CONFIG_OPTIONS.SFTP],
        [CONFIG_OPTIONS.AS2]: DEFAULT_CONFIGS[CONFIG_OPTIONS.AS2],
        [CONFIG_OPTIONS.Upload]: DEFAULT_CONFIGS[CONFIG_OPTIONS.Upload],
    };

    const documentConfiguration = {
        service: 'list_of_services',
        documentType: DocumentType.ListOfServices,
        documentTypeName: 'listOfServices',
        activeTab: 'list-of-services',
        documentQueries: DocumentQueries,
        getOverviewData: GET_OVERVIEW_DATA,
        channelPath: CHANNEL_PATH,
        overviewPath: OVERVIEW_PATH,
        configOptions: configOptions,
        itemTypes: ['positions__article_sets__articles'],
        updateMutation: UPDATE_FIELDS_CONFIG,
    };

    return <DocumentConfiguration documentConfiguration={documentConfiguration} {...props} />;
};

export default Configuration;
