import { IModuleProps } from '../../core/utils/modules';

import locales from './locales';
import { ArticleNumberField } from '../../generic_document/components/ArticleNumberField';
export default {
    name: 'murtfeldt',
    locales,
    components: {
        Field: [
            {
                condition: ({ fieldName }) => fieldName === 'raw_material',
                component: ArticleNumberField,
            },
        ],
    },
} as IModuleProps;
