import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import 'moment/locale/de';
import { camelCase } from 'lodash';

import { url } from '../../../core/utils/link';
import { filterValidation } from '../../../assistance/utils';

import SourceFileView from '../../../assistance/containers/SourceFileView';
import HeaderView from '../../../assistance/containers/HeaderView';
import ItemsView from '../../../assistance/containers/ItemsView';

import AssistanceHeader, { useAlert, useErrorAlert } from '../../../assistance/containers/AssistanceHeader';
import AssistanceView from '../../../assistance/containers/AssistanceView';
import AssistanceFinishButton from '../../../assistance/containers/AssistanceFinishButton';
import useLock from '../../../assistance/utils/useLock';
import AssistanceNextButton from '../../../assistance/containers/AssistanceNextButton';
import DiscardModal from '../../../assistance/containers/DiscardModal';
import { AUTO_UPDATE_INTERVAL } from '../../../../constants';
import { DocumentContext, useDocumentStore } from '../../../stores';
import { appInsights } from '../../../core/analytics/applicationInsights';
import {
    openAssistanceEvent,
    reUploadDocumentEvent,
    toggleExtractedDocumentViewEvent,
} from '../../../core/analytics/customEvents';
import { SupportBarButton, useSupportMode } from '../../../support/containers/SupportBar';
import { getLabelingStatus, LabelingStatus } from '../../constants';
import { NavigationContext } from '../../routes';
import { MasterdataBrowserModal } from '../../../masterdata/containers/MasterdataBrowser';
import { ERROR_RESOLUTIONS } from '../../../core/utils/steprunConfig';
import { useApplicationContext } from '../../../core_updated/contexts/ApplicationContext';
import Layout from '../../../core_updated/components/Layout';
import { withIcon } from '../../../core_updated/components/Icon';
import { faArrowUpToLine, faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { AssistanceContextProvider } from './AssistanceContext';
import { useTaskFilters } from '../../../core/utils/filterQuery';
import useAssistanceHandlers from './useAssistanceHandlers';
import DocumentView from './AssistanceView';
import { useFocusTrap } from '../../../core/utils/hooks/useLocation';
import ProgressButton from '../../../core/containers/ProgressButton';
import { ConditionalComponentContextProvider } from '../../../customizations/ConditionalComponentContext';
import ResultModal from './ResultModal';
import EmailsView from './EmailsView';

export const camelCaseFieldName = (fieldName) => fieldName.split('.').map(camelCase).join('.');

export enum TABS_OPTIONS {
    Source = 'source',
    Header = 'header',
    Items = 'items',
    Document = 'document',
    Emails = 'emails',
}

const useDocumentConfig = (channelDocumentConfig) => {
    const documentConfig =
        channelDocumentConfig &&
        Object.fromEntries(channelDocumentConfig.options.map((option) => [camelCase(option.name), option.value]));

    const fieldConfigs =
        channelDocumentConfig?.fields
            ?.filter((f) => f.enabled)
            ?.map((field) => {
                let groupName = undefined;
                let fieldName = field.name;

                if (fieldName.includes('__')) {
                    [groupName, fieldName] = field.name.split('__');
                }

                return {
                    name: camelCase(fieldName),
                    groupName: camelCase(groupName),
                    enabled: field.enabled,
                    valueType: field.valueType,
                    position: field.position,
                    ...Object.fromEntries(field.options?.map((option) => [camelCase(option.name), option.value])),
                };
            }) || [];
    fieldConfigs.sort((a, b) => a.position - b.position);
    return [documentConfig, fieldConfigs];
};

export const ALL_TABS = {
    [TABS_OPTIONS.Emails]: () => <EmailsView />,
    [TABS_OPTIONS.Source]: ({ recordId, record, loading, readOnly, documentConfig, handlers }) => {
        const { t } = useTranslation('assistance');
        const sourceFile = record?.sourceFile;

        return (
            <SourceFileView
                file={sourceFile}
                readOnly={readOnly}
                loading={loading}
                formButtons={
                    <AssistanceNextButton
                        label={t('sourceView.continueButton')}
                        linkTo={
                            recordId &&
                            url(documentConfig.ASSISTANCE_TAB_PATH, { recordId, tab: 'header' }, { keepSearch: true })
                        }
                        onDiscard={handlers.onDiscard}
                    />
                }
            />
        );
    },
    [TABS_OPTIONS.Document]: () => <DocumentView />,
    [TABS_OPTIONS.Header]: ({
        data,
        user,
        document,
        recordId,
        record,
        loading,
        readOnly,
        documentConfig: documentConfiguration,
        handlers,
        explanationToggle,
    }) => {
        const { t, i18n } = useTranslation('assistance');

        const ocr = data?.[documentConfiguration?.documentTypeName + 'ProcessingRecord']?.ocr;
        const canFinishHeaderAssistance = record?.canFinishHeaderAssistance;
        const headerValidationChecks = useMemo(
            () =>
                filterValidation(document?.validationChecks || [], i18n).filter(
                    (check) => !check?.reference?.startsWith('table_id=')
                ),
            [record?.id]
        );

        const config = record?.channel?.config;

        // load new document config
        let [documentConfig, fieldConfigs] = useDocumentConfig(record?.channel?.documentConfig);
        fieldConfigs = fieldConfigs.filter((field) => field.groupName !== 'lineItems');

        // figure out which fields are defined and which of those are optional
        const fieldNames = fieldConfigs.filter((field) => field.enabled).map((field) => field.name);
        const optionalFieldNames = fieldConfigs
            .filter((field) => field.enabled && !field.isRequired)
            .map((field) => field.name);

        const headerFields = useMemo(
            () =>
                (fieldNames && fieldNames.length > 0 ? fieldNames : config?.headerFields || []).map(camelCaseFieldName),
            [record?.id]
        );
        const optionalHeaderFields = useMemo(
            () =>
                (optionalFieldNames && optionalFieldNames.length > 0
                    ? optionalFieldNames
                    : config?.optionalHeaderFields || []
                ).map(camelCaseFieldName),
            [record?.id]
        );

        const client = useApolloClient();
        const customerNumber = document?.customer?.customerNumber;
        const masterDataConfig = record?.channel?.masterDataConfig;
        const headerFieldConfigs = useMemo(
            () =>
                documentConfiguration.getHeaderFieldConfigs(
                    client,
                    record?.id,
                    masterDataConfig,
                    headerFields,
                    documentConfig,
                    fieldConfigs
                ),

            [client, record?.id, customerNumber, masterDataConfig]
        );

        const firstItemsTab = documentConfiguration?.firstItemsTab || TABS_OPTIONS.Items;

        return (
            <HeaderView
                user={user}
                recordId={recordId}
                record={record}
                ocr={ocr}
                document={document}
                onUpdate={handlers.onUpdate}
                onReselect={handlers.onReselect}
                loading={loading}
                readOnly={readOnly}
                headerFields={headerFields}
                optionalHeaderFields={optionalHeaderFields}
                headerValidationChecks={headerValidationChecks}
                fieldConfigs={headerFieldConfigs}
                explanationToggle={explanationToggle}
                formButtons={
                    <AssistanceNextButton
                        disabled={!canFinishHeaderAssistance}
                        label={t('headerView.continueButton')}
                        onClick={() => handlers.onUpdate({ action: 'action:mark_header_correct' })}
                        linkTo={
                            recordId &&
                            url(
                                documentConfiguration.ASSISTANCE_TAB_PATH,
                                { recordId, tab: firstItemsTab },
                                { keepSearch: true }
                            )
                        }
                        onReset={handlers.onReset}
                        onDiscard={handlers.onDiscard}
                    />
                }
            />
        );
    },
    [TABS_OPTIONS.Items]: ({
        user,
        data,
        document,
        recordId,
        record,
        loading,
        readOnly,
        documentConfig: documentConfiguration,
        handlers,
        explanationToggle,
    }) => {
        const { i18n } = useTranslation('assistance');

        const ocr = data?.[documentConfiguration?.documentTypeName + 'ProcessingRecord']?.ocr;
        const config = record?.channel?.config;

        // there is no concept of tables anymore - we just show them at the top all together
        const lineItemValidationChecks = useMemo(
            () =>
                filterValidation(document?.validationChecks, i18n).filter((check) =>
                    check?.reference?.startsWith('table_id=')
                ),
            [record]
        );

        const client = useApolloClient();
        const masterDataConfig = record?.channel?.masterDataConfig;

        const canFinishAssistance = record?.canFinishAssistance;
        const lineItemsName = documentConfiguration?.lineItemsName || 'lineItems';

        // load new document config
        let [documentConfig, fieldConfigs] = useDocumentConfig(record?.channel?.documentConfig);
        fieldConfigs = fieldConfigs.filter((field) => field.groupName === lineItemsName);

        // figure out which fields are defined and which of those are optional
        const fieldNames = fieldConfigs.filter((field) => field.enabled).map((field) => field.name);
        const optionalFieldNames = fieldConfigs
            .filter((field) => field.enabled && !field.isRequired)
            .map((field) => field.name);

        // figure out which fields are defined and which of those are optional
        const lineItemFields = useMemo(() => {
            return fieldNames && fieldNames.length > 0
                ? fieldNames
                : config?.lineItemFields?.map(camelCaseFieldName) || [];
        }, [record]);
        const optionalLineItemFields = useMemo(() => {
            return optionalFieldNames && optionalFieldNames.length > 0
                ? optionalFieldNames
                : config?.optionalLineItemFields?.map(camelCaseFieldName) || [];
        }, [record]);

        // construct field configs to pass down to the fields
        const lineItemFieldConfigs = useMemo(() => {
            return documentConfiguration.getLineItemFieldConfigs(
                client,
                record?.id,
                masterDataConfig,
                documentConfig,
                fieldConfigs,
                handlers,
                document,
                documentConfiguration
            );
        }, [client, record?.id, masterDataConfig, documentConfig]);

        return (
            <ItemsView
                user={user}
                recordId={recordId}
                record={record}
                ocr={ocr}
                document={document}
                items={document?.[lineItemsName]}
                onUpdate={(props) => handlers.onUpdate({ ...props, tab: TABS_OPTIONS.Items })}
                onReselect={(props) => handlers.onReselect({ ...props, tab: TABS_OPTIONS.Items })}
                loading={loading}
                readOnly={readOnly}
                itemFields={lineItemFields}
                optionalItemFields={optionalLineItemFields}
                itemValidationChecks={lineItemValidationChecks}
                fieldConfigs={lineItemFieldConfigs}
                explanationToggle={explanationToggle}
                formButtons={
                    <AssistanceFinishButton
                        disabled={!canFinishAssistance}
                        onReset={handlers.onReset}
                        onDiscard={handlers.onDiscard}
                        onFinish={handlers.onFinish}
                        onFinishAndContinue={handlers.onFinishAndContinue}
                    />
                }
            />
        );
    },
};

const usePagination = ({ user, documentConfiguration, record }) => {
    const searchParams = new URLSearchParams(location.search);

    const [{ queryFilters }] = useTaskFilters({
        user,
        // no default value -> falls back to all channels, ensure channel in url matches record ones so we don't work with invalid ones
        channelId: searchParams.get('channel') === record?.channel?.id ? record?.channel?.id : undefined,
        // these groups are hard filters, so if document is in one of them, we can use its property
        // NOTE: maybe there is a more elegant way to figure out if a document is finished
        finished: record?.stepRun?.executionStatus === 'SUCCEEDED' || record?.deletedAt,
        testing: record?.isTestDocument,
    });

    const { data: overviewData } = useQuery(documentConfiguration.GET_ASSISTANCE_OVERVIEW_DATA, {
        variables: {
            recordId: record?.id,
            filters: queryFilters,
        },
        fetchPolicy: 'network-only',
        skip: !record?.id,
    });

    return overviewData?.[documentConfiguration?.documentTypeName + 'ProcessingRecordAssistancePagination'];
};

const UploadIcon = withIcon(faArrowUpToLine);
const ExternalLinkIcon = withIcon(faExternalLink);

const DocumentAssistance = ({ documentConfiguration, props }) => {
    const { user } = props;
    const { canUseSupportMode, switchSupportUser } = useSupportMode(user);
    const { setChannelId } = useApplicationContext();

    const { recordId, tab } = useParams();

    const navigate = useNavigate();

    const { restoreFocus } = useFocusTrap();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const unlockView = searchParams.has('unlockView'); // TODO: maybe better naming
    const [showOriginalData, setShowOriginalData] = useState(false);
    const [resultModalVisible, setResultModalVisible] = useState(false);

    const { t } = useTranslation('assistance');

    const [refetchQueryParams, setRefetchQueryParams] = useState<any>({});

    const documentStore = useDocumentStore();
    const {
        data,
        error: dataError,
        loading: dataLoading,
        refetch: dataRefetch,
        startPolling,
        stopPolling,
    } = documentStore.getDocumentProcessingRecord(documentConfiguration.documentType, recordId);

    useEffect(() => {
        // Note: we cannot do this in onCompleted, because it is not called when notifyOnNetworkStatusChange is false
        const record = data?.[documentConfiguration?.documentTypeName + 'ProcessingRecord'];
        if (record == null && canUseSupportMode) {
            switchSupportUser({
                recordId,
                documentType: documentConfiguration.documentType,
            }); // will trigger a window reload if successful
        } else if (record) {
            // this is triggered initially but also after re-fetching
            setRecord(record);
        }
    }, [data]);

    const [record, setRecord] = useState(data?.[documentConfiguration?.documentTypeName + 'ProcessingRecord']);
    const [extractedDocument, setExtractedDocument] = useState(
        data?.[documentConfiguration?.documentTypeName + 'ProcessingRecord']?.[
            documentConfiguration?.documentTypeName + 'Extracted'
        ]
    );
    const [ocr, setOcr] = useState(data?.[documentConfiguration?.documentTypeName + 'ProcessingRecord']?.ocr);

    useEffect(() => {
        // this is only called once initially
        const record = data?.[documentConfiguration?.documentTypeName + 'ProcessingRecord'];
        setRecord(record);
        // only gets loaded initially, not when updated but record gets overwritten with response from server
        setExtractedDocument(record?.[documentConfiguration?.documentTypeName + 'Extracted']);
        setChannelId(record?.channel?.id);

        if (record?.ocr) {
            // only loaded initially
            setOcr(record?.ocr);
        }

        if (record != null) {
            appInsights?.trackEvent(
                ...openAssistanceEvent(user, record, record?.[documentConfiguration?.documentTypeName + 'Unsaved'])
            );
        }
        return () => {
            setChannelId(null);
        };
    }, [data?.[documentConfiguration?.documentTypeName + 'ProcessingRecord']?.id]);

    // if current step run is finished => whole flow execution is finished
    const stepRun = record?.stepRun;
    const isRejected = record?.status === 'REJECTED';
    const isPending = stepRun?.executionStatus === 'PENDING';
    const isFinished = stepRun?.executionStatus === 'SUCCEEDED';
    const isAssistanceNeeded = stepRun?.manualOnly && stepRun?.executionStatus == 'WAITING_FOR_MANUAL';

    const labelingStatus = record && getLabelingStatus(record);
    const document = showOriginalData
        ? extractedDocument
        : record?.[documentConfiguration?.documentTypeName + 'Unsaved'];

    const { locked, lockedBy, lock, cleanLock } = useLock(record?.channel?.id, recordId, user?.id);

    const pagination = usePagination({ user, documentConfiguration, record });

    const dataRefetchWithVariables = React.useCallback(() => {
        dataRefetch(refetchQueryParams);
    }, [refetchQueryParams]);

    const handlers = useAssistanceHandlers({
        user,
        documentConfiguration,
        recordId,
        record,
        setRecord,
        dataRefetch: dataRefetchWithVariables,
        lock,
        cleanLock,
        pagination,
    });

    useEffect(() => {
        // in case no data is available yet, we don't know if assistance is needed
        if (isAssistanceNeeded === undefined) return;
        // we are waiting if the execution is not done (!isFinished) but no manual input is required
        if (isFinished ? false : !isAssistanceNeeded || isPending) {
            startPolling(AUTO_UPDATE_INTERVAL);
        } else {
            stopPolling();
        }
    }, [isFinished, isPending, isAssistanceNeeded]);

    const dataErrorAlert = useErrorAlert(t('header.alerts.error.title'), dataError?.message);

    const defaultAlert = useAlert(record, stepRun, locked, lockedBy);

    let alert =
        dataErrorAlert || handlers?.alert || defaultAlert || (record && documentConfiguration?.getAlert?.({ record }));
    let alertButton = undefined;
    if (alert?.resolution === ERROR_RESOLUTIONS.Discard) {
        alertButton = (
            <ProgressButton
                label={t('header.alerts.buttons.discard')}
                onClick={handlers.onDiscard}
                className="button--small assistance-header__alert-button button--secondary"
            />
        );
    } else if (alert?.resolution === ERROR_RESOLUTIONS.Retry) {
        alertButton = (
            <ProgressButton
                label={t('header.alerts.buttons.retry')}
                onClick={handlers.onRetryStep}
                className="button--small assistance-header__alert-button button--secondary"
            />
        );
    } else if (alert?.resolution === ERROR_RESOLUTIONS.Assistance) {
        alertButton = (
            <ProgressButton
                label={t('header.alerts.buttons.assistance')}
                onClick={() => handlers.onReopenForAssistance(alert?.assistanceReason)}
                className="button--small assistance-header__alert-button button--secondary"
            />
        );
    }
    alert = alert ? { ...alert, button: alertButton } : undefined;

    const readOnly =
        showOriginalData || (!unlockView && (locked || isRejected || !isAssistanceNeeded || record?.deletedAt));
    const loading = dataLoading || handlers?.loading;

    const defaultTab = documentConfiguration?.defaultTab || TABS_OPTIONS.Document;
    const tabs = documentConfiguration?.tabs || ALL_TABS;

    const tabNames = Object.keys(tabs);

    useEffect(function redirectToHeaderTabOnMount() {
        if ((tab && tabNames.includes(tab)) || !defaultTab) return;
        navigate(url(documentConfiguration.ASSISTANCE_TAB_PATH, { recordId, tab: defaultTab }, { keepSearch: true }));
    }, []);

    const TabComponent = tabs[tab] || (tabNames.includes(defaultTab) ? tabs[defaultTab] : Object.values(tabs)[0]);

    const tabProps = {
        user,
        data,
        document,
        documentConfig: documentConfiguration,
        recordId,
        record,
        loading,
        readOnly,
        handlers,
        explanationToggle: true,
        setRefetchQueryParams,
        refetchQueryParams,
    };

    const navigationContext = useContext(NavigationContext);

    const [reUploadFile] = useMutation(documentConfiguration.RE_UPLOAD_FILE);
    const handleReUploadFile = (record) => {
        appInsights?.trackEvent(
            ...reUploadDocumentEvent(user, record, record?.[documentConfiguration.documentTypeName + 'Unsaved'])
        );
        return reUploadFile({
            variables: {
                files: [],
                recordId: record?.id,
                channelId: record?.channel?.id,
                isTestingDocument: true,
            },
            fetchPolicy: 'no-cache',
        }).then((res) => {
            const recordId = res?.data?.[documentConfiguration?.documentTypeName + 'UploadFile']?.records?.[0]?.id;
            if (recordId) {
                window.open(url(documentConfiguration.ASSISTANCE_PATH, { recordId }, { keepSearch: true }));
            }
        });
    };

    const renderMLStudioSupportBarButton = () => {
        // Support button to open or send a document for labeling depending on its labeling status

        const isDocumentInMLStudio = record && getLabelingStatus(record) === LabelingStatus.Eligible;
        const translationKey = isDocumentInMLStudio
            ? 'header.alerts.executionFinished.openInMlStudio'
            : 'header.alerts.executionFinished.sendToMlStudio';

        const onClickHandler = isDocumentInMLStudio
            ? () => window.open(process.env.ML_STUDIO_URL + 'record/' + recordId + '/', '_blank')
            : handlers.onSendToLabeling;

        return (
            <SupportBarButton onClick={onClickHandler}>
                {t(translationKey)} <ExternalLinkIcon className="text-xs" />
            </SupportBarButton>
        );
    };

    const toggleShowOriginalData = () => {
        const newValue = !showOriginalData;
        setShowOriginalData(newValue);
        appInsights?.trackEvent(...toggleExtractedDocumentViewEvent(user, record, document, { show: newValue }));
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            // Check for Shift + Command + X key combination
            if (e.shiftKey && e.metaKey && e.key === 'x') {
                toggleShowOriginalData();
            }
        };
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [showOriginalData]);

    const extendedHandlers = useMemo(
        () => ({
            ...handlers,
            onShowOriginalData: (show: boolean) => setShowOriginalData(show),
            showOriginalData,
            onResultModalVisibleChange: setResultModalVisible,
            resultModalVisible,
        }),
        [handlers, showOriginalData, setResultModalVisible, resultModalVisible]
    );

    return (
        <NavigationContext.Provider
            value={{ ...navigationContext, channelId: record?.channel?.id, recordId: record?.id }}
        >
            <Layout
                supportBarControls={
                    <>
                        <SupportBarButton onClick={() => handleReUploadFile(record)} disabled={!record}>
                            <UploadIcon className="text-xs" /> {t('overview.recordRow.actions.debug')}
                        </SupportBarButton>
                        {renderMLStudioSupportBarButton()}
                    </>
                }
            >
                <DocumentContext.Provider value={{ document }}>
                    <AssistanceContextProvider
                        documentConfiguration={documentConfiguration}
                        record={record}
                        setRecord={setRecord}
                        document={document}
                        ocr={ocr}
                        handlers={extendedHandlers}
                        tabs={tabs}
                        pagination={pagination}
                        loading={loading}
                        alert={alert}
                        readOnly={readOnly}
                        isFinished={isFinished}
                        isAssistanceNeeded={isAssistanceNeeded}
                    >
                        <ConditionalComponentContextProvider components={documentConfiguration?.components}>
                            <div className="assistance-wrapper dark-mode-ready flex-1 min-w-0 w-full flex flex-col">
                                {documentConfiguration.AssistanceHeader ? (
                                    <documentConfiguration.AssistanceHeader />
                                ) : (
                                    <AssistanceHeader />
                                )}
                                <AssistanceView>{TabComponent && <TabComponent {...tabProps} />}</AssistanceView>

                                <DiscardModal
                                    initialValue={alert?.message}
                                    visible={handlers.discardModalVisible}
                                    onConfirm={handlers.onDiscardConfirm}
                                    onCancel={handlers.onDiscardReject}
                                />
                                <MasterdataBrowserModal
                                    {...handlers.masterdataBrowserModalState}
                                    visible={handlers.masterdataBrowserModalVisible}
                                    onClose={() => {
                                        handlers.closeMasterdataBrowser();
                                        restoreFocus();
                                    }}
                                />
                                <ResultModal
                                    record={record}
                                    documentConfig={documentConfiguration}
                                    handlers={handlers}
                                    visible={resultModalVisible}
                                    onClose={() => setResultModalVisible(false)}
                                />
                            </div>
                        </ConditionalComponentContextProvider>
                    </AssistanceContextProvider>
                </DocumentContext.Provider>
            </Layout>
        </NavigationContext.Provider>
    );
};

export default DocumentAssistance;
