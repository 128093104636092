import { gql } from '@apollo/client';

// TODO: proper endpoint
export const GET_COMPANY_DETAILS = gql`
    query {
        viewer {
            id
            customer {
                id
                isActive
                streetAddress
                postalCode
                city
                countryCode
                name
                shortName
                sendEmailNotification
                emailNotificationCron
                isAutomationDashboardEnabled
                isBillingSameAsShipping
                invoiceEmail
                billingStreetAddress
                billingPostalCode
                billingCity
                billingCountryCode
                ssoConfig {
                    providerName
                    domain
                    allowSelfRegistration
                    tenantId
                    clientId
                }
                customizationKey
            }
        }
    }
`;

export const UPDATE_CUSTOMER = gql`
    mutation UpdateCustomer($input: UpdateCustomerInput!) {
        updateCustomer(input: $input) {
            success
        }
    }
`;

export const CHANGE_EMAIL_NOTIFICATION_SETTINGS = gql`
    mutation EmailNotificationChange($sendEmailNotification: Boolean!, $emailNotificationCron: String!) {
        emailNotificationChange(
            sendEmailNotification: $sendEmailNotification
            emailNotificationCron: $emailNotificationCron
        ) {
            success
        }
    }
`;

export const UPDATE_SSO_CONFIG = gql`
    mutation ($input: UpdateSSOConfigInput!) {
        updateSsoConfig(input: $input) {
            success
        }
    }
`;

export const GET_INVOICES = gql`
    query GetInvoices($input: GetInvoicesInput!) {
        viewer {
            id
            customer {
                id
                invoices(input: $input) {
                    id
                    invoiceNumber
                    invoiceDate
                    signedInvoiceFileUrl

                    servicePeriodStartDate
                    servicePeriodEndDate

                    subtotal
                    tax
                    total
                    currencyCode
                }
            }
        }
    }
`;

export const GET_CUSTOMER_WEBHOOK_TOKENS = gql`
    query {
        customerWebhookTokens {
            id
            name
            key
            user {
                id
                firstName
                lastName
            }
            created
        }
    }
`;

export const CREATE_CUSTOMER_WEBHOOK_TOKEN = gql`
    mutation ($name: String!) {
        createWebhookToken(name: $name) {
            success
        }
    }
`;

export const DELETE_CUSTOMER_WEBHOOK_TOKEN = gql`
    mutation ($tokenId: UUID!) {
        deleteWebhookToken(tokenId: $tokenId) {
            success
        }
    }
`;

export const REFRESH_CUSTOMER_WEBHOOK_TOKEN = gql`
    mutation ($tokenId: UUID!) {
        refreshWebhookToken(tokenId: $tokenId) {
            success
        }
    }
`;

export const GET_SFTP_PUBLIC_KEYS = gql`
    query {
        viewer {
            id
            customer {
                sftpPublicKeys {
                    DateImported
                    SshPublicKeyBody
                    SshPublicKeyId
                }
            }
        }
    }
`;

export const CREATE_CUSTOMER_SFTP_PUBLIC_KEY = gql`
    mutation ($publicKeyBody: String!) {
        createSftpPublicKey(publicKeyBody: $publicKeyBody) {
            success
        }
    }
`;

export const DELETE_CUSTOMER_SFTP_PUBLIC_KEY = gql`
    mutation ($publicKeyId: String!) {
        deleteSftpPublicKey(publicKeyId: $publicKeyId) {
            success
        }
    }
`;

export const GET_OUTLOOK_ADDINS = gql`
    query outlookAddins {
        outlookAddins {
            id
            name
            createdAt
            updatedAt
            version
            orderChannels {
                id
                name
            }
            invoiceChannels {
                id
                name
            }
            orderConfirmationChannels {
                id
                name
            }
            rfqChannels {
                id
                name
            }
            deliveryNoteChannels {
                id
                name
            }
        }
    }
`;

export const CREATE_OUTLOOK_ADDIN = gql`
    mutation CreateOutlookAddIn(
        $name: String!
        $orderChannels: [UUID]
        $invoiceChannels: [UUID]
        $orderConfirmationChannels: [UUID]
        $rfqChannels: [UUID]
        $deliveryNoteChannels: [UUID]
    ) {
        createOutlookAddIn(
            name: $name
            orderChannels: $orderChannels
            invoiceChannels: $invoiceChannels
            orderConfirmationChannels: $orderConfirmationChannels
            rfqChannels: $rfqChannels
            deliveryNoteChannels: $deliveryNoteChannels
        ) {
            addin {
                id
                name
                orderChannels {
                    id
                    name
                }
                invoiceChannels {
                    id
                    name
                }
                orderConfirmationChannels {
                    id
                    name
                }
                rfqChannels {
                    id
                    name
                }
                deliveryNoteChannels {
                    id
                    name
                }
            }
        }
    }
`;

export const DELETE_OUTLOOK_ADDIN = gql`
    mutation DeleteOutlookAddIn($id: UUID!) {
        deleteOutlookAddIn(id: $id) {
            success
        }
    }
`;

export const UPDATE_OUTLOOK_ADDIN = gql`
    mutation UpdateOutlookAddIn(
        $id: UUID!
        $name: String
        $orderChannels: [UUID]
        $invoiceChannels: [UUID]
        $orderConfirmationChannels: [UUID]
        $rfqChannels: [UUID]
        $deliveryNoteChannels: [UUID]
    ) {
        updateOutlookAddIn(
            id: $id
            name: $name
            orderChannels: $orderChannels
            invoiceChannels: $invoiceChannels
            orderConfirmationChannels: $orderConfirmationChannels
            rfqChannels: $rfqChannels
            deliveryNoteChannels: $deliveryNoteChannels
        ) {
            addin {
                id
                name
                orderChannels {
                    id
                    name
                }
                invoiceChannels {
                    id
                    name
                }
                orderConfirmationChannels {
                    id
                    name
                }
                rfqChannels {
                    id
                    name
                }
                deliveryNoteChannels {
                    id
                    name
                }
            }
        }
    }
`;

export const CREATE_WECLAPP_INTEGRATION = gql`
    mutation CreateWeclappIntegration(
        $name: String!
        $orderChannels: [UUID]
        $apiKey: String!
        $tenant: String!
        $workistTicketCategoryId: String!
        $workistImportTicketStatusIds: String!
        $workistInProgressTicketStatusId: String!
        $workistFinishedTicketStatusId: String!
        $workistFinishedTicketCategoryId: String!
    ) {
        createWeclappIntegration(
            name: $name
            orderChannels: $orderChannels
            apiKey: $apiKey
            tenant: $tenant
            workistTicketCategoryId: $workistTicketCategoryId
            workistImportTicketStatusIds: $workistImportTicketStatusIds
            workistInProgressTicketStatusId: $workistInProgressTicketStatusId
            workistFinishedTicketStatusId: $workistFinishedTicketStatusId
            workistFinishedTicketCategoryId: $workistFinishedTicketCategoryId
        ) {
            integration {
                id
                name
                apiKey
                tenant
                workistTicketCategoryId
                workistImportTicketStatusIds
                workistInProgressTicketStatusId
                workistFinishedTicketStatusId
                workistFinishedTicketCategoryId
                orderChannels {
                    id
                    name
                }
            }
        }
    }
`;

export const DELETE_WECLAPP_INTEGRATION = gql`
    mutation DeleteWeclappIntegration($id: UUID!) {
        deleteWeclappIntegration(id: $id) {
            success
        }
    }
`;

export const TRIGGER_WECLAPP_INTEGRATION_MASTERDATA_UPDATE = gql`
    mutation TriggerWeclappIntegrationMasterDataUpdate($id: UUID!) {
        triggerWeclappIntegrationMasterDataUpdate(id: $id) {
            started
        }
    }
`;

export const UPDATE_WECLAPP_INTEGRATION = gql`
    mutation UpdateWeclappIntegration(
        $id: UUID!
        $name: String!
        $orderChannels: [UUID]
        $apiKey: String!
        $tenant: String!
        $workistTicketCategoryId: String!
        $workistImportTicketStatusIds: String!
        $workistInProgressTicketStatusId: String!
        $workistFinishedTicketStatusId: String!
        $workistFinishedTicketCategoryId: String!
    ) {
        updateWeclappIntegration(
            id: $id
            name: $name
            orderChannels: $orderChannels
            apiKey: $apiKey
            tenant: $tenant
            workistTicketCategoryId: $workistTicketCategoryId
            workistImportTicketStatusIds: $workistImportTicketStatusIds
            workistInProgressTicketStatusId: $workistInProgressTicketStatusId
            workistFinishedTicketStatusId: $workistFinishedTicketStatusId
            workistFinishedTicketCategoryId: $workistFinishedTicketCategoryId
        ) {
            integration {
                id
                name
                apiKey
                tenant
                workistTicketCategoryId
                workistImportTicketStatusIds
                workistInProgressTicketStatusId
                workistFinishedTicketStatusId
                workistFinishedTicketCategoryId
                orderChannels {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_WECLAPP_INTEGRATIONS = gql`
    query weclappIntegrations {
        weclappIntegrations {
            id
            name
            apiKey
            tenant
            workistTicketCategoryId
            workistImportTicketStatusIds
            workistInProgressTicketStatusId
            workistFinishedTicketStatusId
            workistFinishedTicketCategoryId
            activeMasterDataUpdate
            lastMasterDataUpdate
            orderChannels {
                id
                name
            }
        }
    }
`;

export const CREATE_SAGE100_INTEGRATION = gql`
    mutation CreateSage100Integration(
        $name: String!
        $orderChannels: [UUID]
        $entitlementId: String!
        $salesOrderType: String!
        $dataSet: String!
    ) {
        createSage100Integration(
            name: $name
            orderChannels: $orderChannels
            entitlementId: $entitlementId
            salesOrderType: $salesOrderType
            dataSet: $dataSet
        ) {
            redirectUrl
        }
    }
`;

export const DELETE_SAGE100_INTEGRATION = gql`
    mutation DeleteSage100Integration($id: UUID!) {
        deleteSage100Integration(id: $id) {
            success
        }
    }
`;

export const TRIGGER_SAGE100_INTEGRATION_MASTERDATA_UPDATE = gql`
    mutation TriggerSage100IntegrationMasterDataUpdate($id: UUID!) {
        triggerSage100IntegrationMasterDataUpdate(id: $id) {
            started
        }
    }
`;

export const TRIGGER_SAGE100_OAUTH_FLOW = gql`
    mutation TriggerSage100OauthFlow($id: UUID!) {
        triggerSage100OauthFlow(id: $id) {
            redirectUrl
        }
    }
`;

export const INTEGRATIONS_SAGE100_OAUTH2_COMPLETE = gql`
    mutation TriggerSage100OauthFlowCompleteMutation($input: OAuthCompleteInput!) {
        triggerSage100OauthFlowComplete(input: $input) {
            success
        }
    }
`;

export const UPDATE_SAGE100_INTEGRATION = gql`
    mutation UpdateSage100Integration(
        $id: UUID!
        $name: String!
        $orderChannels: [UUID]
        $entitlementId: String!
        $salesOrderType: String!
        $dataSet: String!
    ) {
        updateSage100Integration(
            id: $id
            name: $name
            orderChannels: $orderChannels
            entitlementId: $entitlementId
            salesOrderType: $salesOrderType
            dataSet: $dataSet
        ) {
            integration {
                id
                name
                entitlementId
                salesOrderType
                dataSet
                orderChannels {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_SAGE100_INTEGRATIONS = gql`
    query Sage100Integrations {
        sage100Integrations {
            id
            name
            authState
            entitlementId
            salesOrderType
            dataSet
            activeMasterDataUpdate
            lastMasterDataUpdate
            orderChannels {
                id
                name
            }
        }
    }
`;

export const CREATE_SAGE200PROFESSIONAL_INTEGRATION = gql`
    mutation CreateSage200ProfessionalIntegration(
        $name: String!
        $orderChannels: [UUID]
        $siteId: String!
        $companyId: String!
        $warehouseId: String!
        $lineTypeId: String!
        $defaultTaxCodeId: String!
    ) {
        createSage200professionalIntegration(
            name: $name
            orderChannels: $orderChannels
            siteId: $siteId
            companyId: $companyId
            warehouseId: $warehouseId
            lineTypeId: $lineTypeId
            defaultTaxCodeId: $defaultTaxCodeId
        ) {
            redirectUrl
        }
    }
`;

export const DELETE_SAGE200PROFESSIONAL_INTEGRATION = gql`
    mutation DeleteSage200ProfessionalIntegration($id: UUID!) {
        deleteSage200professionalIntegration(id: $id) {
            success
        }
    }
`;

export const TRIGGER_SAGE200PROFESSIONAL_INTEGRATION_MASTERDATA_UPDATE = gql`
    mutation TriggerSage200ProfessionalIntegrationMasterDataUpdate($id: UUID!) {
        triggerSage200professionalIntegrationMasterDataUpdate(id: $id) {
            started
        }
    }
`;

export const TRIGGER_SAGE200PROFESSIONAL_OAUTH_FLOW = gql`
    mutation TriggerSage200ProfessionalOauthFlow($id: UUID!) {
        triggerSage200professionalOauthFlow(id: $id) {
            redirectUrl
        }
    }
`;

export const INTEGRATIONS_SAGE200PROFESSIONAL_OAUTH2_COMPLETE = gql`
    mutation TriggerSage200ProfessionalOauthFlowCompleteMutation($input: OAuthCompleteInput!) {
        triggerSage200professionalOauthFlowComplete(input: $input) {
            success
        }
    }
`;

export const UPDATE_SAGE200PROFESSIONAL_INTEGRATION = gql`
    mutation UpdateSage200ProfessionalIntegration(
        $id: UUID!
        $name: String!
        $orderChannels: [UUID]
        $siteId: String!
        $companyId: String!
        $warehouseId: String!
        $lineTypeId: String!
        $defaultTaxCodeId: String!
    ) {
        updateSage200professionalIntegration(
            id: $id
            name: $name
            orderChannels: $orderChannels
            siteId: $siteId
            companyId: $companyId
            warehouseId: $warehouseId
            lineTypeId: $lineTypeId
            defaultTaxCodeId: $defaultTaxCodeId
        ) {
            integration {
                id
                name
                siteId
                companyId
                warehouseId
                lineTypeId
                defaultTaxCodeId
                orderChannels {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_SAGE200PROFESSIONAL_INTEGRATIONS = gql`
    query Sage200ProfessionalIntegrations {
        sage200professionalIntegrations {
            id
            name
            authState
            siteId
            companyId
            warehouseId
            lineTypeId
            defaultTaxCodeId
            activeMasterDataUpdate
            lastMasterDataUpdate
            orderChannels {
                id
                name
            }
        }
    }
`;
