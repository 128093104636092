import { gql } from '@apollo/client';
import { FULL_CHANNEL } from './pages/Configuration/queries';
import { BASE_DOCUMENT, BASE_FIELD, PRIMITIVE_FIELD, SELECT_FIELD_FRAGMENT } from '../assistance/queries';

const FULL_ORDER = gql`
    ${SELECT_FIELD_FRAGMENT}
    
    fragment FullOrder on Order {
        ${BASE_DOCUMENT}
        customer {
            name
            customerNumber
            customerType
            misc
            contactPerson
            ${BASE_FIELD}
            address {
                addressId
                name
                postcode
                city
                country
                phone
                email
                companyName
                streetAndNr
                misc
                bbox
                pageIndex
                predictionConfidence
            }
            internalNote
        }
        customerContact {
            name
            contactId
            phone
            email
            ${BASE_FIELD}
        }
        dispatchContact {
            name
            contactId
            phone
            email
            ${BASE_FIELD}
        }

        deliveryEndDate {
            ${PRIMITIVE_FIELD}
        }
        deliveryDate {
            ${PRIMITIVE_FIELD}
        }
        deliveryTimeFrom {
            ${PRIMITIVE_FIELD}
        }
        deliveryTimeUntil {
            ${PRIMITIVE_FIELD}
        }
        orderDate {
            ${PRIMITIVE_FIELD}
        }

        orderNumber {
            ${PRIMITIVE_FIELD}
        }
        orderType {
            ${PRIMITIVE_FIELD}
        }
        documentType {
            ${PRIMITIVE_FIELD}
        }
        frameworkContractNumber {
            ${PRIMITIVE_FIELD}
        }
        
        goodsReceivingTime {
            ${PRIMITIVE_FIELD}
        }
        
        currency {
            ...FullSelectField
        }
        totalAmountNet {
            value
            bbox
            pageIndex
            predictionConfidence
            validationChecks {
                name
                result
                targetValue
            }
        }
        totalAmount {
            ${PRIMITIVE_FIELD}
        }

        deliveryTerms {
            ${PRIMITIVE_FIELD}
        }
        shippingTerms {
            ${PRIMITIVE_FIELD}
        }

        incoterm {
            ...FullSelectField
        }

        deliveryUnloadMethod {
            ...FullSelectField
        }

        vertriebsOrg {
            ${PRIMITIVE_FIELD}
        }
        quoteNumber {
            ${PRIMITIVE_FIELD}
        }

        projectNumber {
            ${PRIMITIVE_FIELD}
        }

        vatId {
            ${PRIMITIVE_FIELD}
        }
        paymentTerms {
            ... on StringField {
                ${PRIMITIVE_FIELD}
            }
            ... on SelectField {
                ...FullSelectField
            }
        }
        goodsIssueDate {
            ${PRIMITIVE_FIELD}
        }

        deliveryAddress {
            addressId
            addressId2
            name
            postcode
            city
            countrySubdivision
            country
            phone
            email
            companyName
            companyName2
            companyName3
            companyName4
            streetAndNr
            misc
            ${BASE_FIELD}
        }

        invoiceAddress {
            addressId
            name
            postcode
            city
            countrySubdivision
            country
            phone
            email
            companyName
            companyName2
            companyName3
            companyName4
            streetAndNr
            misc
            ${BASE_FIELD}
        }
        infoText {
            ${PRIMITIVE_FIELD}
        }

        commissionNumber {
            ${PRIMITIVE_FIELD}
        }
        lineItems {
            tableId
            ${BASE_FIELD}

            pos {
                ${PRIMITIVE_FIELD}
            }
            orderNumber {
                ${PRIMITIVE_FIELD}
            }
            customer {
                name
                customerNumber
                customerType
                misc
                contactPerson
                ${BASE_FIELD}
                address {
                    addressId
                    name
                    postcode
                    city
                    countrySubdivision
                    country
                    phone
                    email
                    companyName
                    streetAndNr
                    misc
                    bbox
                    pageIndex
                    predictionConfidence
                }
            }
            articleNumber {
                ${PRIMITIVE_FIELD}
            }
            articleNumber2 {
                ${PRIMITIVE_FIELD}
            }
            articleNumber3 {
                ${PRIMITIVE_FIELD}
            }

            clientArticleNumber {
                ${PRIMITIVE_FIELD}
            }

            articlePartition {
                ${PRIMITIVE_FIELD}
            }
            articlePartitionSpecificNumber {
                ${PRIMITIVE_FIELD}
            }
            articleVariant1 {
                ...FullSelectField
            }
            articleCommissionNumber {
                ${PRIMITIVE_FIELD}
            }
            articleCustomsTariffNumber {
                ${PRIMITIVE_FIELD}
            }
            articleTaxCode {
                ${PRIMITIVE_FIELD}
            }

            articleDeliveryDate {
                ${PRIMITIVE_FIELD}
            }
            articleDeliveryTimeFrom {
                ${PRIMITIVE_FIELD}
            }
            articleDeliveryTimeUntil {
                ${PRIMITIVE_FIELD}
            }
            articleDiscount {
                ${PRIMITIVE_FIELD}
            }
            articleDiscountAmount {
                ${PRIMITIVE_FIELD}
            }
            articleComments {
                ${PRIMITIVE_FIELD}
            }
            articleDescription {
                ${PRIMITIVE_FIELD}
            }
            articleCurrency {
                ...FullSelectField
            }
            articleDescriptionMasterData {
                ${PRIMITIVE_FIELD}
            }
            grossPrice {
                ${PRIMITIVE_FIELD}
            }
            salesOrganisation {
                ...FullSelectField
            }
            articleMarkings {
                ${PRIMITIVE_FIELD}
            }

            batchNumber {
                ${PRIMITIVE_FIELD}
            }

            unitPrice {
                ${PRIMITIVE_FIELD}
            }
            priceUnit {
                ...FullSelectField
            }
            totalPrice {
                ${PRIMITIVE_FIELD}
            }
            unit {
                ...FullSelectField
            }
            quantity {
                ${PRIMITIVE_FIELD}
            }
            convertedUnit {
                ...FullSelectField
            }
            convertedQuantity {
                ${PRIMITIVE_FIELD}
            }
            quantityAndUnit {
                ${PRIMITIVE_FIELD}
            }
            goodsIssueDate {
                ${PRIMITIVE_FIELD}
            }
            colorText1 {
                ${PRIMITIVE_FIELD}
            }
            frameworkContractNumber {
                ${PRIMITIVE_FIELD}
            }
            quoteNumber {
                ${PRIMITIVE_FIELD}
            }
            dynamicSchemaSerialized
        }
        isErpLocked
        dynamicSchemaSerialized
    }
`;

const FULL_RECORD = gql`
    ${FULL_ORDER}
    ${FULL_CHANNEL}
    fragment FullRecord on OrderProcessingRecord {
        id
        createdAt
        deletedAt
        deletedBy {
            email
            firstName
            lastName
        }

        createdBy {
            email
            firstName
            lastName
        }

        status

        sender
        subject

        canFinishAssistance
        canFinishHeaderAssistance
        canFinishLineItemsAssistance

        isHeaderCorrect: canFinishHeaderAssistance(ignoreThreshold: false)
        isLineItemsCorrect: canFinishLineItemsAssistance(ignoreThreshold: false)

        lockedBy {
            email
        }

        putOnHoldReason
        putOnHoldBy {
            email
            firstName
            lastName
        }

        assistedBy {
            email
            firstName
            lastName
        }

        assistedAt

        stepRun {
            id
            stepName
            manualOnly
            executionStatus
            error
            context
        }

        orderprocessingsteprunSet {
            id
            createdAt
            updatedAt
            stepName
            manualOnly
            executionStatus
            context
            error
        }

        channel {
            ...FullChannel
        }

        isDiscarded
        isTestDocument

        discardRecord {
            reason
            user {
                id
                firstName
                lastName
            }
            createdAt
            updatedAt
        }

        sourceFile {
            ... on Email {
                sender {
                    email
                    name
                }
                receivers {
                    email
                    name
                }
                subject
                messageId
                language
                receivedAt

                text
                html
                attachments {
                    cid
                    contentDisposition
                    name
                    type
                    url
                }

                senderClientId
                prediction
                predictionConfidence
                sourceUrl

                predictionConfidences
            }

            ... on File {
                name
                type
                url
            }
        }

        outputFile {
            ... on Export {
                files {
                    name
                    url
                }
            }
        }

        orderUnsaved {
            ...FullOrder
        }

        externalReference
        deliveryRecord {
            deliveryMethod
            deliveryStatus
            externalReference
            externalMessage
            externalStatus
        }
        labelingStatus

        masterdataVersionsExtracted
    }
`;

export const GET_OVERVIEW_DATA = gql`
    query {
        orderProcessingChannels {
            id
            name
        }
    }
`;

export const GET_CHANNELS_OVERVIEW_DATA = gql`
    query {
        orderProcessingChannels {
            id
            name
            emailAddress
            team {
                id
                users {
                    email
                    firstName
                    lastName
                }
            }
        }
    }
`;

export const GET_TABLE_OVERVIEW_DATA = gql`
    query ($filters: [TableFilter], $orderBy: String, $offset: Int, $first: Int) {
        filteredOrderProcessingRecordsList(filters: $filters, orderBy: $orderBy, offset: $offset, limit: $first) {
            totalCount
            records {
                id
                sender
                subject
                status
                createdAt
                finishedAt
                assistedAt
                assistedBy {
                    id
                    firstName
                    lastName
                    email
                }
                createdBy {
                    email
                    firstName
                    lastName
                }
                deletedAt
                deletedBy {
                    id
                    firstName
                    lastName
                    email
                }
                lockedBy {
                    email
                }
                stepRun {
                    id
                    stepName
                    manualOnly
                    executionStatus
                    error
                    context
                }
                channel {
                    id
                    name
                }
                isDiscarded
                externalReference
                deliveryRecord {
                    deliveryMethod
                    deliveryStatus
                    externalReference
                    externalMessage
                    externalStatus
                }
                discardRecord {
                    reason
                    user {
                        id
                        firstName
                        lastName
                        email
                    }
                    createdAt
                    updatedAt
                }
                isTestDocument
                canProcessAutomatically
                sourceFile {
                    ... on File {
                        name
                    }
                    ... on Email {
                        sender {
                            name
                            email
                        }
                    }
                }
            }
        }
    }
`;

export const GET_ASSISTANCE_OVERVIEW_DATA = gql`
    query ($recordId: UUID!, $filters: [TableFilter]) {
        orderProcessingRecordAssistancePagination(recordId: $recordId, filters: $filters) {
            filteredCount
            position
            previousRecordId
            nextRecordId
        }
    }
`;

export const GET_ASSISTANCE_DATA = gql`
    ${FULL_RECORD}
    ${FULL_ORDER}
    query ($id: UUID!) {
        orderProcessingRecord(id: $id) {
            ...FullRecord

            orderExtracted {
                ...FullOrder
            }

            ocr {
                pagesCount
                processedFile {
                    url
                }
            }
        }
    }
`;

export const UPDATE_PROCESSING_RECORD = gql`
    mutation ($recordId: UUID!, $fieldName: String, $itemIndex: Int, $action: String!, $payload: GenericScalar) {
        orderUpdate(
            recordId: $recordId
            fieldName: $fieldName
            itemIndex: $itemIndex
            action: $action
            payload: $payload
        ) {
            record {
                canFinishAssistance
                canFinishHeaderAssistance
                canFinishLineItemsAssistance

                isHeaderCorrect: canFinishHeaderAssistance(ignoreThreshold: false)
                isLineItemsCorrect: canFinishLineItemsAssistance(ignoreThreshold: false)
            }

            patch {
                op
                path
                from
                value
            }
        }
    }
`;

export const RESELECT_PROCESSING_RECORD = gql`
    mutation ($recordId: UUID!, $fieldName: String, $bbox: [[Float]]!, $pageIndex: Int!, $itemIndex: Int) {
        orderReselect(
            recordId: $recordId
            fieldName: $fieldName
            bbox: $bbox
            pageIndex: $pageIndex
            itemIndex: $itemIndex
        ) {
            record {
                canFinishAssistance
                canFinishHeaderAssistance
                canFinishLineItemsAssistance

                isHeaderCorrect: canFinishHeaderAssistance(ignoreThreshold: false)
                isLineItemsCorrect: canFinishLineItemsAssistance(ignoreThreshold: false)
            }

            patch {
                op
                path
                from
                value
            }
        }
    }
`;

export const ORDER_EXTRACT_TEXT = gql`
    mutation OrderExtractText($recordId: UUID!, $pageIndex: Int!, $bbox: [[Float]]!) {
        orderExtractText(recordId: $recordId, pageIndex: $pageIndex, bbox: $bbox) {
            bbox
            text
        }
    }
`;

export const DELETE_ORDER = gql`
    mutation ($recordId: UUID!) {
        orderDelete(recordId: $recordId) {
            success
        }
    }
`;

export const RETRY_STEP = gql`
    mutation ($recordId: UUID!, $stepRunId: UUID!) {
        orderRetryStep(recordId: $recordId, stepRunId: $stepRunId) {
            success
        }
    }
`;

export const DISCARD_ORDER = gql`
    mutation ($recordId: UUID!, $reason: String) {
        orderDiscard(recordId: $recordId, reason: $reason) {
            success
        }
    }
`;

export const REOPEN_FOR_ASSISTANCE = gql`
    mutation ($recordId: UUID!, $reason: String) {
        orderReopenForAssistance(recordId: $recordId, reason: $reason) {
            success
        }
    }
`;

export const UPLOAD_FILE = gql`
    mutation ($channelId: UUID!, $files: [Upload]!, $batchId: String, $isTestingDocument: Boolean!) {
        orderUploadFile(
            input: { channelId: $channelId, files: $files, batchId: $batchId, isTestingDocument: $isTestingDocument }
        ) {
            records {
                id
            }
            success
        }
    }
`;

export const RE_UPLOAD_FILE = gql`
    mutation ($channelId: UUID!, $files: [Upload]!, $recordId: String!, $isTestingDocument: Boolean!) {
        orderUploadFile(
            input: { channelId: $channelId, files: $files, recordId: $recordId, isTestingDocument: $isTestingDocument }
        ) {
            records {
                id
            }
            success
        }
    }
`;

export const SEND_TO_LABELING = gql`
    mutation ($recordId: UUID!) {
        orderSendToLabeling(recordId: $recordId) {
            success
        }
    }
`;

export const GET_NEXT_ASSISTANCE_RECORD = gql`
    query ($filters: [TableFilter], $channelId: UUID) {
        nextOrderProcessingRecord(filters: $filters, channelId: $channelId) {
            id
        }
    }
`;

export const CREATE_CHANNEL = gql`
    mutation ($name: String!, $fromExistingChannelId: UUID) {
        createOrderProcessingChannel(name: $name, fromExistingChannelId: $fromExistingChannelId) {
            channel {
                id
            }
            success
        }
    }
`;

export const GET_CUSTOMER_AUTOMATION_RATIO = gql`
    query ($startDate: Date!, $endDate: Date!) {
        customerOrderAutomationRatio(startDate: $startDate, endDate: $endDate) {
            automationDays {
                date
                assistedCount
                automaticCount
                totalCount
                automationRatio
            }
            last5DaysAutomationRatio
        }
    }
`;

export const GET_CHANNEL_AUTOMATION_RATIO = gql`
    query ($channelId: UUID!, $startDate: Date!, $endDate: Date!) {
        channelOrderAutomationRatio(channelId: $channelId, startDate: $startDate, endDate: $endDate) {
            automationDays {
                date
                assistedCount
                automaticCount
                totalCount
                automationRatio
            }
            last5DaysAutomationRatio
        }
    }
`;

export const GET_ALL_CHANNELS = gql`
    ${FULL_CHANNEL}
    query {
        orderProcessingChannels {
            ...FullChannel
        }
    }
`;

export const GET_PROCESSING_RECORD_EMAILS = gql`
    query ($recordId: UUID!) {
        orderDocumentEmails(recordId: $recordId) {
            original {
                subject
                text
                html
                sender {
                    name
                    email
                }
                receivers {
                    name
                    email
                }
                attachments {
                    name
                    type
                    url
                }
            }
            sent {
                id
                createdAt
                subject
                body
                mimetype
                sender {
                    email
                    name
                }
                receivers {
                    email
                    name
                }
                carbonCopy {
                    email
                    name
                }
                blindCarbonCopy {
                    email
                    name
                }
                attachments {
                    name
                    url
                }
            }
            sourceFile {
                name
                url
            }
        }
    }
`;

export const SEND_ASSISTANCE_EMAIL = gql`
    mutation ($recordId: UUID!, $email: DocumentEmailInput!) {
        orderSendDocumentEmail(email: $email, recordId: $recordId) {
            emailId
            errors
        }
    }
`;
