import * as React from 'react';
import {
    CREATE_CHANNEL,
    DELETE_LIST_OF_SERVICES,
    GET_CHANNEL_AUTOMATION_RATIO,
    GET_CUSTOMER_AUTOMATION_RATIO,
    GET_TABLE_OVERVIEW_DATA,
    RE_UPLOAD_FILE,
    RETRY_STEP,
    UPLOAD_FILE,
    GET_CHANNELS_OVERVIEW_DATA,
} from '../../queries';
import DocumentOverview from '../../../generic_document/pages/Overview';
import * as constants from '../../constants';
import { DocumentType } from '../../../generic_document/constants';

const Overview = (props) => {
    const documentConfiguration = {
        documentType: DocumentType.ListOfServices,
        documentTypeName: 'listOfServices',
        activeTab: 'list-of-services',
        constants: constants,
        CREATE_CHANNEL: CREATE_CHANNEL,
        DELETE_RECORD: DELETE_LIST_OF_SERVICES,
        GET_CHANNEL_AUTOMATION_RATIO: GET_CHANNEL_AUTOMATION_RATIO,
        GET_CUSTOMER_AUTOMATION_RATIO: GET_CUSTOMER_AUTOMATION_RATIO,
        GET_CHANNELS_OVERVIEW_DATA: GET_CHANNELS_OVERVIEW_DATA,
        GET_TABLE_OVERVIEW_DATA: GET_TABLE_OVERVIEW_DATA,
        RETRY_STEP: RETRY_STEP,
        UPLOAD_FILE: UPLOAD_FILE,
        RE_UPLOAD_FILE: RE_UPLOAD_FILE,
    };

    return <DocumentOverview documentConfiguration={documentConfiguration} props={props} />;
};

export default Overview;
