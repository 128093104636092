import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
    mutation login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            success
            throttled
        }
    }
`;

export const LOGOUT_USER = gql`
    mutation logout {
        logout {
            success
        }
    }
`;

export const PROVIDER_LOGIN = gql`
    mutation OAuthLogin($input: OAuthLoginInput!) {
        oauthLogin(input: $input) {
            redirectUrl
        }
    }
`;

export const PROVIDER_PROVIDER_LOGIN = gql`
    mutation OAuthProviderLogin($input: OAuthProviderLoginInput!) {
        oauthProviderLogin(input: $input) {
            redirectUrl
        }
    }
`;

export const PROVIDER_AUTHORIZED = gql`
    mutation OAuthAuthorized($input: OAuthAuthorizedInput!) {
        oauthAuthorized(input: $input) {
            redirectUrl
        }
    }
`;

export const FORGOT_PASSWORD = gql`
    mutation ResetPassword($email: String!) {
        resetPassword(email: $email) {
            done
        }
    }
`;

export const RESET_PASSWORD = gql`
    mutation ResetPasswordConfirm($uid: String!, $token: String!, $newPassword1: String!, $newPassword2: String!) {
        resetPasswordConfirm(uid: $uid, token: $token, newPassword1: $newPassword1, newPassword2: $newPassword2) {
            success
        }
    }
`;

// maybe there is a better name as this is not only creating the user but also the customer
export const CREATE_USER = gql`
    mutation CreateUser($input: CreateUserInput!) {
        createUser(input: $input) {
            success
        }
    }
`;
