import { IModuleProps } from '../../core/utils/modules';

import locales from './locales';
import { CountryField } from '../../generic_document/components/AddressField';

export default {
    name: 'siemens_smart_infrastructure',
    locales,
    components: {
        Field: [
            {
                condition: ({ fieldName }) => fieldName === 'end_customer__country',
                component: CountryField,
            },
        ],
    },
} as IModuleProps;
